import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { environment } from '@/config/environment';
import { useAuth } from '@/lib/hooks/useAuth';
import { DEFAULT_CALENDAR_PARAMS, DEFAULT_ROOMS_PARAMS } from '@/pages/Calendar';

import { ProfileMenu } from './users/ProfileMenu';

function _Header() {
  const { user, logasUser } = useAuth();
  const { t } = useTranslation('layouts/header');
  const navigate = useNavigate();
  const location = useLocation();
  const isRooms = location.pathname.startsWith('/rooms');

  const toggleRoomView = useCallback(() => {
    if (isRooms) {
      navigate({
        pathname: '/calendar',
        search: createSearchParams(DEFAULT_CALENDAR_PARAMS).toString(),
      });
    } else {
      navigate({
        pathname: '/rooms',
        search: createSearchParams(DEFAULT_ROOMS_PARAMS).toString(),
      });
    }
  }, [isRooms, navigate]);

  if (!user) {
    return null;
  }

  return (
    <Box
      as="header"
      w="full"
      borderRadius="md"
      shadow="md"
      bg="thirdBgColor"
      color="primaryFontColor"
    >
      {logasUser && (
        <Alert status="warning" w="full" justifyContent="center" borderTopRadius="md">
          <AlertIcon />
          {t('logged_as')}&nbsp;
          <Text fontWeight="bold" textTransform="capitalize">
            {user.firstName} {user.lastName}
          </Text>
        </Alert>
      )}
      <Flex justify="center" align="center" h="60px" px={4}>
        <Image src={`/logos/${environment.LOGO_PATH}.png`} w="32px" h="32px" mr={4} />
        <Heading size="md">
          {environment.NAME}&nbsp;
          <Text color="secondFontColor" fontSize={14}>
            {t('title')}
          </Text>
        </Heading>
        <Spacer />
        <Button mr={4} onClick={toggleRoomView}>
          {isRooms ? t('Calendar') : t('Rooms')}
        </Button>
        <ProfileMenu user={user} />
      </Flex>
    </Box>
  );
}

export const Header = React.memo(_Header);
