import { Box, Container, VStack } from '@chakra-ui/react';
import { withProfiler, withSentryReactRouterV6Routing } from '@sentry/react';
import React, { lazy, Suspense, useEffect } from 'react';
import { createSearchParams, Navigate, Route, Routes } from 'react-router-dom';

import { Header } from './components/Header';
import { AuthGuard } from './components/router/AuthGuard';
import { Can } from './components/ui-elements/Can';
import { LoadingModal } from './components/ui-elements/LoadingModal';
import { environment } from './config/environment';
import { defineTitle } from './lib/helpers/utils';
import { AuthenticationPage } from './pages/Authentication';
import { Calendar } from './pages/Calendar/Calendar';
import { CalendarPage } from './pages/Calendar/Page';
import { DEFAULT_CALENDAR_PARAMS } from './pages/Calendar/utils';

const EventDetails = lazy(() => import('./pages/EventDetails'));
const EventCreate = lazy(() => import('./pages/EventCreate'));
const EventEdit = lazy(() => import('./pages/EventEdit'));

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export const App: React.FC = () => {
  useEffect(() => {
    document.title = defineTitle(environment.NAME, environment.ENV);
  }, []);

  return (
    <Container as={VStack} h="100vh" py={4} minW="full" px={{ base: 6, '2xl': 12 }} gap={4}>
      <Header />
      <Box flex={1} borderRadius="md" position="relative" w="full">
        <SentryRoutes>
          <Route path="/login" element={<AuthenticationPage />} />
          <Route element={<AuthGuard />}>
            <Route path="calendar" element={<CalendarPage />}>
              <Route
                path="events/:id/details"
                element={
                  <Suspense fallback={<LoadingModal />}>
                    <EventDetails />
                  </Suspense>
                }
              />
              <Route
                path="events/:id/edit"
                element={
                  <>
                    <Can I="update" an="event">
                      <Suspense fallback={<LoadingModal />}>
                        <EventEdit />
                      </Suspense>
                    </Can>
                    <Can not I="update" an="event">
                      <Navigate to="/calendar" />
                    </Can>
                  </>
                }
              />
              <Route
                path="events/create"
                element={
                  <>
                    <Can I="create" an="event">
                      <Suspense fallback={<LoadingModal />}>
                        <EventCreate />
                      </Suspense>
                    </Can>
                    <Can not I="create" an="event">
                      <Navigate to="/calendar" />
                    </Can>
                  </>
                }
              />
            </Route>
            <Route path="/rooms" element={<Calendar isTimeline={true} />} />
            <Route
              path="/"
              element={
                <Navigate
                  to={{
                    pathname: '/calendar',
                    search: createSearchParams(DEFAULT_CALENDAR_PARAMS).toString(),
                  }}
                />
              }
            />
          </Route>
        </SentryRoutes>
      </Box>
    </Container>
  );
};

export default withProfiler(App);
